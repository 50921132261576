.cards {
  padding: 3rem;
  background: #fff;
  }

  .container{
    background: #ffffff;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    object-fit: contain;
  }


  .container-image{
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    margin: 0 auto;
    max-width: 1520px;
    width: 100%;
    height: auto;
  }

  h2{
    font-size: large;
    text-align: center;
    width: 100%;
  }

  .container > h1 {
    color: #000000;
    font-size: auto;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
    text-shadow: 2px 2px 4px  #3b3636;
    margin-bottom: 10px;
  }


  /* ------------------------CONTAINER DE INFORMACION------------------------ */

  .cardsInfo {
    /* width: 100%; */
    padding: 4rem;
    background: #f0eeee;
  }
  
  .container-info-row {
    background-color: #ffffff;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 0px ;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; /* Añade esta línea para centrar verticalmente */
    max-width: 1120px;
    width: 100%;
  }
  .StyledInput {
    width: 100%;
  }
  
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  


  .logo-container {
    display: flex;
    flex-direction: column;
  }

  .info > h1 {
    width: 100%;
    text-align: center;
    color: #000000;
    font-size: 1.5rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
    margin-bottom: 10px;
  }
  
  .imagen {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 10px; /* Espaciado entre las imágenes */
  }


  .custom-form {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .custom-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: auto;
  }
  
  .custom-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .custom-form button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .custom-form button:hover {
    background-color: #0056b3;
  }

  /* Estilos del botón de WhatsApp */
.whatsApp-container {
  background-color: transparent;
    /* box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 20px ; */
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Añade esta línea para centrar verticalmente */
    max-width: 1120px;
    width: 100%;

}
.whatsApp-container h1 {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: auto;
 }
 .whatsApp-container img {
  width: 100%;
  height: auto;
 }

 hr{
  color: #f2ebeb;
 }

 /* Estilos del botón "Obtener información por correo" */
.email-container {
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: 20px auto;
  text-align: center;
}

.email-container:hover {
  background-color: #e5f0fc;
}

/* Estilos del botón "Escríbenos por WhatsApp" */
.whatsapp-container {
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: 20px auto;
  text-align: center;
}

.whatsapp-container:hover {
  background-color: #128c7e28;
}
