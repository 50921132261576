video {
  object-fit: cover;
  width: 100vw; /* Cambia a 100vw para cubrir la ventana completa */
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  }

  .hero-container {
    position: relative;
  height: 100vh;
  width: 100%;
 /* padding: 5rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
  }
  
  .hero-container > h1 {
    text-shadow: 2px 2px 3px rgb(0, 0, 0);
    color: #fff;
    font-size: 80px;
    margin-top: -100px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }


  .title {
    margin: 8px auto; /* Agregué auto a la izquierda y derecha para centrar horizontalmente */
    color: #fff;
    font-size: 100px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
    text-align: center; /* Añadí esta propiedad para centrar horizontalmente */
  }
  

  
  .hero-container > p {
    position: relative;
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
